@import '../../../styles/index';

.base {
  padding: 1.25rem 1.5rem;
  background-color: $color-white--100;
  display: flex;
  border-radius: 0.375rem;
  box-shadow: $shadow-eng-map-filter-menu;
  flex-wrap: wrap;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 1.25rem;
  }
}
